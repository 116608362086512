import React from "react";
import { styled } from "styled-components";

const CardContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #faf9f4;
    box-sizing: border-box;
    /* width: 100%; */
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
`;

const CardBodyContainer = styled.div``;

const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
`;

export default function Card({ header, body, footer, footerCenter = true }) {
    return (
        <CardContainer>
            <CardBodyContainer>
                <div>{header}</div>
                <div>{body}</div>
            </CardBodyContainer>
            <FooterContainer
                className="card-footer"
                style={{ flexDirection: `${footerCenter ? "row" : "column"}` }}
            >
                {footer &&
                    footer.map((item, index) => <div key={index}>{item}</div>)}
            </FooterContainer>
        </CardContainer>
    );
}
