import React, { createContext, useState } from "react";

export const UserStoryContext = createContext();

export const UserStoryState = ({ children }) => {
    const [contextData, setContextData] = useState([]);
    const [viewDetailRecordID, setViewDetailRecordID] = useState("");
    const [viewDetailTableName, setViewDetailTableName] = useState("");

    const AddContextData = (data) => {
        const temp = [...contextData, data];
        setContextData(temp);
    };

    const GetPreviousContextData = (templateName) => {
        if (contextData.length > 0) {
            const findData = contextData.find(
                (item) => item.templateName === templateName,
            );
            if (findData) {
                return findData.values;
            }
            return null;
        }
        return null;
    };

    const SetViewDetailData = (tableName, RecordID) => {
        console.log(tableName, RecordID);
        setViewDetailTableName(tableName);
        setViewDetailRecordID(RecordID);
    };

    const ResetViewDetailData = () => {
        setViewDetailTableName("");
        setViewDetailRecordID("");
    };

    const contextValue = {
        contextData,
        AddContextData,
        GetPreviousContextData,
        SetViewDetailData,
        ResetViewDetailData,
        viewDetailTableName,
        viewDetailRecordID,
    };

    return (
        <UserStoryContext.Provider value={contextValue}>
            {children}
        </UserStoryContext.Provider>
    );
};
