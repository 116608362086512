import React, { useContext } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { CgRadioCheck } from "react-icons/cg";
import styled from "styled-components";
import { UserStoryContext } from "../context/UserStoryState";
import Modal from "./Modal";
import OpCodeEditForm from "./OpCodeEditForm";
import { LoaderSpinner } from "./LoaderSpinner";

const TableContainer = styled.button`
    background-color: white;
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    padding: 0px;
    border: none;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 5px white;
    width: 100%;
    font-size: 16px;
`;

const TableScroll = styled.div`
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 10px;
        background-color: #888;
        border-radius: 20px;
        margin: 10px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bb0000;
        border-radius: 20px;
    }
`;

const TableStyle = styled.table`
    border-collapse: collapse;
`;

const NoData = styled.div`
    width: 100%;
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
    font-weight: bolder;
    padding: 20px;
`;

const TableHeaderContainer = styled.thead`
    background-color: var(--bright-red-color);
    font-weight: 500;
    color: white;
`;

const TableHeader = styled.th`
    border: 1.5px solid #b0bec570;
    text-align: start;
    padding: 10px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TableBodyContainer = styled.tbody`
    background-color: white;
    color: black;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        padding: 8px;
        background-color: #b0bec540;
    }
`;

const TableData = styled.td`
    border: 1.5px solid #b0bec570;
    text-align: start;
    padding: 10px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export default function Table({
    OpCodeEditTemplateName,
    tableName,
    tableCol,
    tableData,
    totalFetchedRow = 0,
    tableDisplayOrder = "row",
    haveSelect = false,
    isFetching,
}) {
    const [toggleButtonId, setToggleButtonId] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [editRecordId, setEditRecordId] = React.useState(null);

    const { SetViewDetailData } = useContext(UserStoryContext);

    const handleRowClick = (userId) => {
        setToggleButtonId(userId);
        if (haveSelect) {
            SetViewDetailData(tableName, userId);
        }
    };

    return (
        <TableContainer>
            {isFetching === false ? (
                tableCol && totalFetchedRow > 0 ? (
                    <>
                        <TableScroll>
                            <TableStyle>
                                <TableHeaderContainer>
                                    <tr>
                                        {tableCol && (
                                            <>
                                                {haveSelect && (
                                                    <TableHeader></TableHeader>
                                                )}
                                                {tableCol.map((item, index) => (
                                                    <TableHeader
                                                        key={index}
                                                        style={{
                                                            width: item.size,
                                                            maxWidth: item.size,
                                                        }}
                                                    >
                                                        {item.title}
                                                    </TableHeader>
                                                ))}
                                                <TableHeader></TableHeader>
                                            </>
                                        )}
                                    </tr>
                                </TableHeaderContainer>
                                <TableBodyContainer>
                                    {tableDisplayOrder === "row" &&
                                        tableData &&
                                        tableData.tableRow &&
                                        tableData.tableRow.length > 0 &&
                                        tableData.tableRow.map(
                                            (item, itemIndex) => (
                                                <TableRow key={itemIndex}>
                                                    {haveSelect && (
                                                        <TableData
                                                            style={{
                                                                width: "30px",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div
                                                                onClick={() =>
                                                                    handleRowClick(
                                                                        item.recordId,
                                                                    )
                                                                }
                                                                className="toggle-button"
                                                            >
                                                                {toggleButtonId ===
                                                                item.recordId ? (
                                                                    <AiFillCheckCircle
                                                                        style={{
                                                                            color: "#bb0000",
                                                                            fontSize:
                                                                                "23px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <CgRadioCheck
                                                                        style={{
                                                                            fontSize:
                                                                                "23px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </TableData>
                                                    )}
                                                    {tableCol.map(
                                                        (tableItem, index) => (
                                                            <TableData
                                                                key={index}
                                                                style={{
                                                                    width: tableItem.size,
                                                                    maxWidth:
                                                                        tableItem.size,
                                                                }}
                                                            >
                                                                {
                                                                    item[
                                                                        tableItem
                                                                            .field
                                                                    ]
                                                                }
                                                            </TableData>
                                                        ),
                                                    )}

                                                    <TableData
                                                        style={{
                                                            width: "50px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setShowModal(
                                                                    (prev) =>
                                                                        !prev,
                                                                );
                                                                setEditRecordId(
                                                                    item.recordId,
                                                                );
                                                            }}
                                                            style={{
                                                                backgroundColor:
                                                                    "#bb0000",
                                                                color: "white",
                                                                border: "none",
                                                                padding:
                                                                    "5px 10px",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </TableData>
                                                </TableRow>
                                            ),
                                        )}
                                    {tableDisplayOrder === "column" &&
                                        tableData &&
                                        tableData.tableCol &&
                                        tableData.tableColHeader.length > 0 &&
                                        Object.keys(tableData.tableCol).length >
                                            0 &&
                                        Array.from(
                                            {
                                                length: tableData.tableCol[
                                                    "recordId"
                                                ].length,
                                            },
                                            (_, index) => (
                                                <TableRow key={index}>
                                                    {haveSelect && (
                                                        <TableData
                                                            style={{
                                                                width: "30px",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div
                                                                onClick={() =>
                                                                    handleRowClick(
                                                                        tableData
                                                                            .tableCol
                                                                            .recordId[
                                                                            index
                                                                        ],
                                                                    )
                                                                }
                                                                className="toggle-button"
                                                            >
                                                                {toggleButtonId ===
                                                                tableData
                                                                    .tableCol
                                                                    .recordId[
                                                                    index
                                                                ] ? (
                                                                    <AiFillCheckCircle
                                                                        style={{
                                                                            color: "#bb0000",
                                                                            fontSize:
                                                                                "23px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <CgRadioCheck
                                                                        style={{
                                                                            fontSize:
                                                                                "23px",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </TableData>
                                                    )}
                                                    {tableCol.map((item, i) => (
                                                        <TableData
                                                            key={i}
                                                            style={{
                                                                width: item.size,
                                                                maxWidth:
                                                                    item.size,
                                                            }}
                                                        >
                                                            {tableData.tableCol[
                                                                item.field
                                                            ] &&
                                                                tableData
                                                                    .tableCol[
                                                                    item.field
                                                                ][index]}
                                                        </TableData>
                                                    ))}

                                                    <TableData
                                                        style={{
                                                            width: "50px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setShowModal(
                                                                    (prev) =>
                                                                        !prev,
                                                                );
                                                                setEditRecordId(
                                                                    tableData
                                                                        .tableCol
                                                                        .recordId[
                                                                        index
                                                                    ],
                                                                );
                                                            }}
                                                            style={{
                                                                backgroundColor:
                                                                    "#bb0000",
                                                                color: "white",
                                                                border: "none",
                                                                padding:
                                                                    "5px 10px",
                                                                borderRadius:
                                                                    "5px",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </TableData>
                                                </TableRow>
                                            ),
                                        )}
                                </TableBodyContainer>
                            </TableStyle>
                        </TableScroll>
                        {tableData && showModal && (
                            <Modal
                                $isOpen={showModal}
                                setOpenModal={setShowModal}
                            >
                                <Modal.Header>OP Code Edit Form</Modal.Header>
                                <Modal.Body>
                                    <OpCodeEditForm
                                        recordId={editRecordId}
                                        templateName={OpCodeEditTemplateName}
                                        tableName={tableName}
                                    />
                                </Modal.Body>
                            </Modal>
                        )}
                    </>
                ) : (
                    <>
                        {
                            <>
                                {tableData && tableData.length === 0 && (
                                    <NoData>No data found!</NoData>
                                )}
                            </>
                        }
                    </>
                )
            ) : (
                <NoData>
                    <LoaderSpinner />
                </NoData>
            )}
        </TableContainer>
    );
}
