import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const LoaderContainer = styled.div`
    animation: ${rotate} 1s infinite linear;
`;

const AppLoaderContainer = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoaderContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
`;

export const LoaderSpinner = ({ ...loadingProps }) => {
    return (
        <LoaderContainer>
            <AiOutlineLoading {...loadingProps} />
        </LoaderContainer>
    );
};

export const AppLoader = ({ ...loadingProps }) => {
    return (
        <AppLoaderContainer>
            <LoaderContainer>
                <AiOutlineLoading {...loadingProps} />
            </LoaderContainer>
        </AppLoaderContainer>
    );
};
