/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from "react";
import { httpRequest, httpRequest2, httpRequest3 } from "../utils/httpsRequest";
import { AppLoader } from "./LoaderSpinner";
import { PrimaryButton } from "./Buttons";
import { CreateForm } from "./Form";
import Card from "./Card";
import { useContext } from "react";
import { UserStoryContext } from "../context/UserStoryState";
import { Context } from "../context/GlobalState";

const initialCreateState = {
    template: {
        templateName: "",
        tableName: "",
        formComponent: {},
    },
    formData: {},
    fieldErrors: {},
    fieldValidateValues: {},
    prevFrameData: {},
    loading: true,
};

const createReducer = (state, action) => {
    switch (action.type) {
        case "SET_TEMPLATE":
            return { ...state, template: action.payload };
        case "SET_FORM_DATA":
            return { ...state, formData: action.payload };
        case "SET_FIELD_ERRORS":
            return { ...state, fieldErrors: action.payload };
        case "SET_FIELD_VALIDATE_VALUES":
            return { ...state, fieldValidateValues: action.payload };
        case "SET_LOADING":
            return { ...state, loading: action.payload };
        case "SET_PREV_FRAME_DATA":
            return { ...state, prevFrameData: action.payload };
        default:
            return state;
    }
};

export default function OpCodeCreateForm({
    templateName,
    prevFrame = null,
    isUserStory = false,
}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { addNewNotifcation } = useContext(Context);
    const { GetPreviousContextData } = useContext(UserStoryContext);
    const [state, dispatch] = useReducer(createReducer, initialCreateState);
    const { template, formData, prevFrameData, fieldErrors, loading } = state;
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        const getTemplate = async () => {
            if (isUserStory && prevFrame !== null) {
                const prevFrameDataTemp = GetPreviousContextData(prevFrame);
                if (prevFrameDataTemp) {
                    dispatch({
                        type: "SET_FORM_DATA",
                        payload: prevFrameDataTemp,
                    });
                }
            }
            // await httpRequest
            //     .get(
            //         `/bexo/insights/bex3d-templates/opcode/create/${templateName}`,
            //     )
            await httpRequest2
                .get(`/api/template/${templateName}`)
                .then(async (response) => {
                    console.log(response.data);
                    if (response.data) {
                        const data = response.data;
                        dispatch({
                            type: "SET_TEMPLATE",
                            payload: response.data,
                        });
                    }
                    // // console.log(response.data.payload);
                    // if (response.data.payload) {
                    //     const data = response.data.payload;
                    //     dispatch({
                    //         type: "SET_TEMPLATE",
                    //         payload: response.data.payload,
                    //     });
                    // }
                })
                .catch((error) => {
                    console.log(error);
                    addNewNotifcation("Failed to load Template", "danger");
                });
        };
        loading && dispatch({ type: "SET_LOADING", payload: false });

        getTemplate();
    }, [templateName]);

    const handleClear = () => {
        dispatch({ type: "SET_FORM_DATA", payload: {} });
        if (isUserStory && prevFrame !== null) {
            dispatch({ type: "SET_FORM_DATA", payload: prevFrameData });
        }
    };

    const validateForm = () => {
        const requiredFields = template.formComponent.fields.filter(
            (field) => field.required,
        );
        const newFieldErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field.key || field.field]) {
                newFieldErrors[field.key || field.field] =
                    `${field.label} is required`;
            } else {
                newFieldErrors[field.key || field.field] = "";
            }
        });

        dispatch({ type: "SET_FIELD_ERRORS", payload: newFieldErrors });

        return Object.values(newFieldErrors).every((error) => error === "");
    };

    const createPayload = (data, tableName) => {
        if (
            data &&
            tableName &&
            Object.keys(data).length > 0 &&
            tableName !== ""
        ) {
            const payLoad = {
                Meta: {
                    Child: {
                        Name: tableName,
                        Type: "Child Create",
                    },
                    Action: "create",
                },
                ChildRecords: [data],
            };
            return payLoad;
        }
    };

    const handleSubmit = async () => {
        const isValid = validateForm();

        if (isValid) {
            const createPayloadData = createPayload(
                formData,
                template.formComponent.tadabaseTableName,
            );
            console.log("created:", createPayloadData);
            await httpRequest3
                .post(`/beso/actions/bex-action/tb`, createPayloadData)
                .then(async (response) => {
                    if (response.data) {
                        // const data = response.data.payload;
                        console.log(response.data);
                        if (response.data.bulkCount.success === 1) {
                            addNewNotifcation(
                                "Record Created Successfully",
                                "success",
                            );
                            handleClear();
                        } else {
                            addNewNotifcation(
                                "Record Creation Failed",
                                "danger",
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    addNewNotifcation("Record Creation Failed", "danger");
                });
        }
    };

    useEffect(() => {
        if (template && template.tableName !== "") {
            const requiredFields = template.formComponent.fields.filter(
                (field) => field.required,
            );
            // console.log(requiredFields);
            // console.log(formData);

            const isAllRequiredFieldsFilled = requiredFields.every(
                (field) => formData[field.key || field.field],
            );

            setIsSubmitDisabled(!isAllRequiredFieldsFilled);
        }
    }, [formData, fieldErrors]);

    return loading === false ? (
        <div>
            <>
                {template &&
                    template.tableName !== "" &&
                    template.formComponent &&
                    template.formComponent.fields.length > 0 &&
                    template.formComponent.isBulk === false && (
                        <Card
                            body={
                                <CreateForm
                                    formData={formData}
                                    fields={template.formComponent.fields}
                                    windowWidth={windowWidth}
                                    fieldErrors={fieldErrors}
                                    dispatch={dispatch}
                                />
                            }
                            footer={[
                                <PrimaryButton
                                    $text="Submit"
                                    onClick={handleSubmit}
                                    disabled={isSubmitDisabled}
                                />,
                                <PrimaryButton
                                    $text="Clear"
                                    onClick={handleClear}
                                    disabled={false}
                                />,
                            ]}
                        />
                    )}
            </>
        </div>
    ) : (
        <div>
            <AppLoader />
        </div>
    );
}
