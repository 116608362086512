import React, { useEffect, useState } from "react";
import "./user-story-styles.css";
import OpCodeSearchForm from "../../components/OpCodeSearchForm";
import OpCodeViewDetail from "../../components/OpCodeViewDetail";
import OpCodeCreateForm from "../../components/OpCodeCreateForm";

export default function UserStory() {
    const [storyTemplate, setStoryTemplate] = useState({
        storyTemplateNumber: "",
        title: "",
        frames: [],
    });
    const [frameData, setFrameData] = useState({});
    const [fields, setFields] = useState([]);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        fetch("/userStoryTemplate_1.json")
            .then((response) => response.json())
            .then((data) => {
                setStoryTemplate(data);
                setFrameData(data.frames[0]);
                console.log(data);
            });
    }, []);

    const changeFrame = () => {
        if (storyTemplate.frames.length !== 0) {
            const index = storyTemplate.frames.findIndex(
                (item) =>
                    item.frameTemplateName === frameData.frameTemplateName,
            );
            if (index + 1 < storyTemplate.frames.length) {
                setFrameData(storyTemplate.frames[index + 1]);
            }
        }
    };

    return (
        <div>
            {storyTemplate.frames.length !== 0 && (
                <div className="user-story-page">
                    <h1 className="user-story-heading">
                        {storyTemplate.title}
                    </h1>
                    <div>
                        <div className="user-story-frame-container">
                            <div className="user-story-op-code-search-container">
                                <h2 className="user-story-heading">
                                    {frameData.actionTitle}
                                </h2>
                                {frameData.action === "search" && (
                                    <div>
                                        <OpCodeSearchForm
                                            setFields={setFields}
                                            setUserId={setUserId}
                                            templateName={
                                                frameData.frameTemplateName
                                            }
                                            prevFrame={frameData.previousFrame}
                                            needCreate={false}
                                            isUserStory={true}
                                        />
                                    </div>
                                )}
                                {frameData.action === "create" && (
                                    <div>
                                        <OpCodeCreateForm
                                            templateName={
                                                frameData.frameTemplateName
                                            }
                                            prevFrame={frameData.previousFrame}
                                            isUserStory={true}
                                        />
                                    </div>
                                )}
                                {frameData.action === "search-create" && (
                                    <div>
                                        <OpCodeSearchForm
                                            setFields={setFields}
                                            setUserId={setUserId}
                                            templateName={
                                                frameData.frameTemplateName
                                            }
                                            prevFrame={frameData.previousFrame}
                                            needCreate={true}
                                            isUserStory={true}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="user-story-view-details-container">
                                <h2 className="user-story-heading">
                                    {frameData.viewDetailTitle}
                                </h2>
                                <OpCodeViewDetail
                                    templateName={frameData.frameTemplateName}
                                    data={{
                                        user_id: userId,
                                        fields: fields,
                                        target: frameData.frameTableName,
                                    }}
                                    changeFrame={changeFrame}
                                    buttonActive={frameData.haveNextFrame}
                                    contextField={frameData.contextField}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
