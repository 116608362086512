/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { styled } from "styled-components";
import Modal from "../../components/Modal";
import { CreateForm, SearchForm } from "../../components/Form";
import "./home-styles.css";
import { Fa500Px } from "react-icons/fa";

//Styling
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";

const LinkStyle = styled.div`
    margin: 10px 0;
    width: 100%;
    padding: 10px;
    border-left: 4px solid #b1092a;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${(props) =>
        props.index % 2 === 0 ? "#EBEFF0" : "white"};
    span {
        margin-right: 5px;
        color: #bb0000;
        font-weight: bold;
    }

    a {
        text-decoration: none;
        color: #000;
    }

    a:hover {
        text-decoration: underline;
        color: #bb0000;
    }
`;

export default function Home() {
    const [templateName, setTemplateName] = useState("");
    const [jsonData, setJsonData] = useState({});

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);

    console.log("process.env.NODE_ENV: ", process.env.REACT_APP_NODE_ENV);
    const baseURL =
        process.env.REACT_APP_NODE_ENV === "development"
            ? "http://localhost:3000"
            : "https://bex3d-templates.netlify.app";

    const checkTemplateValidityForOpCodeSearch = (jsonData) => {
        if (
            !jsonData?.templateName ||
            !jsonData?.tableName ||
            !jsonData?.searchComponent?.fields?.length ||
            !jsonData?.tableComponent?.tableCol?.length
        ) {
            return false;
        }
        return true;
    };

    const checkTemplateValidityForOpCodeCreate = (data) => {
        if (
            !data?.templateName ||
            !data?.tableName ||
            !data?.formComponent?.fields?.length
        ) {
            return false;
        }
        return true;
    };

    const checkTemplateValid = (data) => {
        if (
            !data?.templateName ||
            !data?.tableName ||
            !data?.searchComponent?.fields?.length ||
            !data?.tableComponent?.tableCol?.length ||
            !data?.formComponent?.fields?.length
        ) {
            return false;
        } else {
            return true;
        }
    };

    const links = [
        {
            name: "OpCode Search",
            routeDirectory: "op-code-search",
            newTab: true,
            hasTemplateName: true,
            prefix: "search-",
        },
        {
            name: "OpCode Create",
            routeDirectory: "op-code-create",
            newTab: true,
            hasTemplateName: true,
            prefix: "create-",
        },
        {
            name: "OpCode Bulk Create",
            routeDirectory: "op-code-bulk-create",
            newTab: true,
            hasTemplateName: true,
            prefix: "create-bulk-",
        },
        {
            name: "OpCode Edit",
            routeDirectory: "op-code-edit",
            newTab: true,
            hasTemplateName: true,
            prefix: "update-",
        },
        {
            name: "User Story",
            routeDirectory: "user-story",
            newTab: true,
            hasTemplateName: false,
        },
    ];

    const handleJsonChange = (e) => {
        let inputValue = e.target.value;

        // Check if the input value is a file
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            // Read the file as text
            reader.onload = (event) => {
                inputValue = event.target.result;
                setJsonData(parseJson(inputValue));
            };

            // Read the file content
            reader.readAsText(file);
        } else {
            setJsonData(parseJson(inputValue));
        }
    };

    const parseJson = (inputValue) => {
        try {
            return JSON.parse(inputValue);
        } catch (error) {
            return {};
        }
    };

    return (
        <div>
            <div className="space-before-container">
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
                    integrity="sha512-nmr+vMjlIoUqfF/UzpczO6AGb9u9l5uH86DgpiMsf52E1NlKg3/SckWZzDzunlBWqABpmFfMs1k1C4dkPdWWMdw=="
                    crossorigin="anonymous"
                />

                <div className="inputContainer">
                    <h2>View Template</h2>
                    <div className="inputWithIcon">
                        <input
                            id="inputTemplate"
                            type="text"
                            placeholder="Template Name"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                        {/* <FontAwesomeIcon icon={faSearch} className="icon" /> */}
                    </div>
                </div>
                <div>
                    {links.map((item, index) => (
                        <LinkStyle key={index} index={index}>
                            <span>{item.name}: </span>
                            <a
                                href={`${baseURL}/${item.routeDirectory}?templateName=${item.prefix}${templateName}`}
                                target={item.newTab ? "_blank" : "_self"}
                            >
                                {baseURL}/{item.routeDirectory}
                                {item.hasTemplateName
                                    ? `?templateName=${item.prefix}${templateName}`
                                    : ""}
                            </a>
                        </LinkStyle>
                    ))}
                </div>
            </div>
            <div className="space-before-container">
                <h2>Create New Template</h2>
                <textarea
                    id="templateJsonInput"
                    rows="25"
                    cols="208"
                    value={
                        JSON.stringify(jsonData || {}, null, 4) ||
                        JSON.stringify({}, null, 4)
                    }
                    onChange={handleJsonChange}
                />
                <br />
                <div className="buttonContainer">
                    <button
                        id="previewSearch"
                        onClick={() => {
                            setShowSearchModal((prev) => !prev);
                        }}
                        disabled={
                            !checkTemplateValidityForOpCodeSearch(jsonData)
                        }
                    >
                        Preview OpCode Search
                    </button>

                    <Modal
                        $isOpen={showSearchModal}
                        setOpenModal={setShowSearchModal}
                    >
                        <Modal.Header>
                            Search Form - {jsonData.templateName}
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div>
                                    {checkTemplateValidityForOpCodeSearch(
                                        jsonData,
                                    ) ? (
                                        <SearchForm
                                            formData={jsonData}
                                            fields={
                                                jsonData.searchComponent.fields
                                            }
                                            windowWidth={window.innerWidth}
                                            dispatch={() => {}}
                                        />
                                    ) : (
                                        "invalid template"
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <br />
                    <br />
                    <button
                        id="previewCreate"
                        onClick={() => {
                            setShowCreateModal((prev) => !prev);
                        }}
                        disabled={
                            !checkTemplateValidityForOpCodeCreate(jsonData)
                        }
                    >
                        Preview OpCode Create
                    </button>

                    <Modal
                        $isOpen={showCreateModal}
                        setOpenModal={setShowCreateModal}
                    >
                        <Modal.Header>
                            Create Form - {jsonData?.tableName}
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {checkTemplateValidityForOpCodeCreate(
                                    jsonData,
                                ) ? (
                                    <CreateForm
                                        formData={jsonData}
                                        fields={jsonData.formComponent.fields}
                                        windowWidth={window.innerWidth}
                                        dispatch={() => {}}
                                    />
                                ) : (
                                    "invalid template"
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>

                    <br />
                    <br />
                    <button
                        id="saveBtn"
                        disabled={!checkTemplateValid(jsonData)}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="space-before-container">
                <h2>Remove Template</h2>
                <div className="removeContainer">
                    <input
                        id="removeTemplate"
                        type="text"
                        placeholder="Template Name"
                    />
                    <button id="removeBtn">
                        Remove
                        {/* <FontAwesomeIcon icon={faTrash} className="icon" /> */}
                    </button>
                </div>
            </div>
        </div>
    );
}
