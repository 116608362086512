import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
    border: 0px;
    // border-bottom: 1px solid rgba(240, 240, 240, 1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 5px 0px;
    background-color: white;

    &:focus {
        outline: none;
        color: black;
        background-color: white;
        border-bottom: 2px solid #b1092a;
    }

    &::placeholder {
        color: black;
    }

    &[type="radio"] {
        & + label {
            color: #000 !important;
        }
    }
`;

const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

export const InputField = (props) => {
    const { item, formData, dispatch } = props;

    if (!item) {
        return null;
    }

    return (
        <StyledInput
            type={item?.type || item?.inputType}
            name={item?.key || item?.field}
            id={item?.key || item?.field}
            placeholder={item?.placeholder}
            value={formData[item?.key || item?.field] || ""}
            onChange={(e) => {
                dispatch({
                    type: "SET_FORM_DATA",
                    payload: {
                        ...formData,
                        [item?.key || item?.field]: e.target.value,
                    },
                });
            }}
            style={{
                width: `${item?.width ? item?.width : "100%"}`,
            }}
        />
    );
};

export const RadioField = (props) => {
    const { item, formData, dispatch } = props;
    if (!item) {
        return null;
    }
    return (
        <RadioContainer
            id={item.key || item.field}
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            }}
        >
            {item.values.map((value, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                    }}
                >
                    <StyledInput
                        type="radio"
                        id={value}
                        name={item?.key || item?.field}
                        value={value || ""}
                        checked={formData[item?.key || item?.field] === value}
                        onChange={(e) => {
                            dispatch({
                                type: "SET_FORM_DATA",
                                payload: {
                                    ...formData,
                                    [item?.key || item?.field]: e.target.value,
                                },
                            });
                        }}
                    />
                    <label htmlFor={value}>{value}</label>
                </div>
            ))}
        </RadioContainer>
    );
};
