/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useReducer, useState } from "react";
import { styled } from "styled-components";
import { httpRequest, httpRequest1, httpRequest2 } from "../utils/httpsRequest";
import { AppLoader } from "./LoaderSpinner";
import { SearchForm } from "./Form";
import { PrimaryButton } from "./Buttons";
import Table from "./Table";
import { Pagination } from "./Pagination";
import Card from "./Card";
import Modal from "./Modal";
import OpCodeCreateForm from "./OpCodeCreateForm";
import OpCodeBulkCreateForm from "./OpCodeBulkCreateForm";
import { UserStoryContext } from "../context/UserStoryState";

const SearchToggleContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #faf9f450;
    padding: 10px;
    border-radius: 15px;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 100%;
`;

const CreateSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 100%;
`;

const initialSearchState = {
    template: {
        templateName: "",
        tableName: "",
        searchComponent: {},
        tableComponent: {},
        OpCodeEditTemplateName: "",
    },
    formData: {},
    currentPage: 1,
    tableData: {},
    totalPage: 0,
    totalFetchedRow: 0,
    totalRows: 0,
    prevFrameData: {},
};

const searchReducer = (state, action) => {
    switch (action.type) {
        case "SET_TEMPLATE":
            return { ...state, template: action.payload };
        case "SET_FORM_DATA":
            return { ...state, formData: action.payload };
        case "SET_TABLE_DATA":
            return { ...state, tableData: action.payload };
        case "SET_CURRENT_PAGE":
            return { ...state, currentPage: action.payload };
        case "SET_TOTAL_PAGE":
            return { ...state, totalPage: action.payload };
        case "SET_FETCHED_TOTAL_ROW":
            return { ...state, totalFetchedRow: action.payload };
        case "SET_TOTAL_ROWS":
            return { ...state, totalRows: action.payload };
        case "SET_PREVIOUS_CONTEXT_DATA":
            return { ...state, prevFrameData: action.payload };
        default:
            return state;
    }
};

export default function OpCodeSearchForm({
    templateName,
    prevFrame = null,
    isUserStory = false,
    needCreate = false,
    isBulkCreate = false,
}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [state, dispatch] = useReducer(searchReducer, initialSearchState);
    const {
        template,
        formData,
        tableData,
        currentPage,
        totalPage,
        totalFetchedRow,
        totalRows,
        prevFrameData,
    } = state;

    const [showSearchForm, setShowSearchForm] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const { GetPreviousContextData } = useContext(UserStoryContext);

    const searchPayload = (templateData, formData, page) => {
        if (
            templateData?.tableName !== "" &&
            templateData?.searchComponent.fields.length > 0 &&
            templateData?.tableComponent.tableCol
        ) {
            const payLoad = {
                target: templateData.tableName,
                action: "search",
                filters: {
                    logicalOperator:
                        templateData.searchComponent.logicalOperator,
                    rules:
                        formData && Object.keys(formData).length > 0
                            ? templateData.searchComponent.fields
                                  .map((field) => {
                                      if (formData[field.field]) {
                                          const rule = {
                                              field: field.field,
                                              dataType: field.dataType,
                                              comparisonOperator:
                                                  field.comparisonOperator,
                                              value: formData[field.field],
                                          };
                                          if (field.foreignTable) {
                                              rule.foreignTable =
                                                  field.foreignTable;
                                          }
                                          if (field.foreignField) {
                                              rule.foreignField =
                                                  field.foreignField;
                                          }
                                          return rule;
                                      }
                                  })
                                  .filter((rule) => rule !== undefined)
                            : [
                                  {
                                      field: "recordId",
                                      dataType: "string",
                                      comparisonOperator: "notEquals",
                                      value: "null",
                                  },
                              ],
                },
                columnsToRetrieve: [
                    {
                        field: "recordId",
                        dataType: "string",
                    },
                    ...(templateData?.tableComponent?.tableCol.map((item) => {
                        const column = {
                            field: item.field,
                            dataType: item.dataType,
                        };
                        if (item.foreignTable) {
                            column.foreignTable = item.foreignTable;
                        }
                        if (item.foreignField) {
                            column.foreignField = item.foreignField;
                        }
                        return column;
                    }) || []),
                ],
                resultDisplayOrder:
                    templateData.tableComponent.resultDisplayOrder,
                maxRowsPerPage: templateData.tableComponent.maxRowsPerPage,
                pageToRetrieve: page,
            };
            return payLoad;
        }
    };

    useEffect(() => {
        const getTemplate = async () => {
            if (prevFrame !== null && isUserStory === true) {
                const prevFrameDataTemp = GetPreviousContextData(prevFrame);
                if (prevFrameDataTemp !== null) {
                    dispatch({
                        type: "SET_FORM_DATA",
                        payload: prevFrameDataTemp,
                    });
                    dispatch({
                        type: "SET_PREVIOUS_CONTEXT_DATA",
                        payload: prevFrameDataTemp,
                    });
                }
            }
            // await httpRequest
            // .get(
            //     `/bexo/insights/bex3d-templates/opcode/search/${templateName}`,
            // )
            await httpRequest2.get(`/api/template/${templateName}`).then(
                async (response) => {
                    console.log(response.data);
                    if (response.data.length !== 0) {
                        dispatch({
                            type: "SET_TEMPLATE",
                            payload: response.data,
                        });
                        const totalRowsPerPage =
                            response.data.tableComponent.maxRowsPerPage;
                        const tableDisplayOrder =
                            response.data.tableComponent.resultDisplayOrder;

                        const payLoad = searchPayload(
                            response.data,
                            formData,
                            0,
                        );

                        // console.log(payLoad);

                        await httpRequest1
                            .post("/BESInsights/search", payLoad)
                            .then(
                                (response) => {
                                    // console.log(response.data);
                                    if (!response.data.error) {
                                        dispatch({
                                            type: "SET_TABLE_DATA",
                                            payload: response.data,
                                        });
                                        dispatch({
                                            type: "SET_TOTAL_PAGE",
                                            payload: Math.ceil(
                                                parseInt(
                                                    response.data.totalRecords,
                                                ) / totalRowsPerPage,
                                            ),
                                        });
                                        if (tableDisplayOrder === "row") {
                                            dispatch({
                                                type: "SET_FETCHED_TOTAL_ROW",
                                                payload:
                                                    response.data.tableRow
                                                        .length,
                                            });
                                        } else {
                                            dispatch({
                                                type: "SET_FETCHED_TOTAL_ROW",
                                                payload:
                                                    response.data.tableCol[
                                                        "recordId"
                                                    ].length,
                                            });
                                        }
                                        dispatch({
                                            type: "SET_TOTAL_ROWS",
                                            payload: Math.ceil(
                                                parseInt(
                                                    response.data.totalRecords,
                                                ),
                                            ),
                                        });
                                    } else {
                                        dispatch({
                                            type: "SET_TABLE_DATA",
                                            payload: [],
                                        });
                                        dispatch({
                                            type: "SET_TOTAL_PAGE",
                                            payload: 0,
                                        });
                                        dispatch({
                                            type: "SET_FETCHED_TOTAL_ROW",
                                            payload: 0,
                                        });
                                        dispatch({
                                            type: "SET_TOTAL_ROWS",
                                            payload: 0,
                                        });
                                    }
                                },
                                (error) => {
                                    console.log(error);
                                },
                            );
                    }
                },
                (error) => {
                    console.log(error);
                },
            );
            isFetching && setIsFetching(false);
        };

        getTemplate();
    }, [templateName]);

    const search = async (page, clear = false) => {
        let payLoad = {};
        if (template && template.tableName !== "") {
            if (Object.keys(formData).length === 0 || clear === true) {
                payLoad = searchPayload(
                    template,
                    {},
                    (page - 1) * template.tableComponent.maxRowsPerPage,
                );
            } else {
                payLoad = searchPayload(
                    template,
                    formData,
                    (page - 1) * template.tableComponent.maxRowsPerPage,
                );
            }
            console.log(payLoad);
            await httpRequest1.post("/BESInsights/search", payLoad).then(
                (response) => {
                    console.log(response.data);
                    if (!response.data.error) {
                        dispatch({
                            type: "SET_TABLE_DATA",
                            payload: response.data,
                        });
                        dispatch({
                            type: "SET_TOTAL_PAGE",
                            payload: Math.ceil(
                                parseInt(response.data.totalRecords) /
                                    template.tableComponent.maxRowsPerPage,
                            ),
                        });
                        if (
                            template.tableComponent.resultDisplayOrder === "row"
                        ) {
                            dispatch({
                                type: "SET_FETCHED_TOTAL_ROW",
                                payload: response.data.tableRow.length,
                            });
                        } else {
                            dispatch({
                                type: "SET_FETCHED_TOTAL_ROW",
                                payload:
                                    response.data.tableCol["recordId"].length,
                            });
                        }
                        dispatch({
                            type: "SET_TOTAL_ROWS",
                            payload: Math.ceil(
                                parseInt(response.data.totalRecords),
                            ),
                        });
                        setIsFetching(false);
                    } else {
                        dispatch({
                            type: "SET_TABLE_DATA",
                            payload: [],
                        });
                        dispatch({
                            type: "SET_TOTAL_PAGE",
                            payload: 0,
                        });
                        dispatch({
                            type: "SET_FETCHED_TOTAL_ROW",
                            payload: 0,
                        });
                        dispatch({
                            type: "SET_TOTAL_ROWS",
                            payload: 0,
                        });
                    }
                },
                (error) => {
                    console.log(error);
                },
            );
            setIsFetching(false);
        }
        setIsFetching(false);
    };

    const handleSubmit = async () => {
        dispatch({ type: "SET_CURRENT_PAGE", payload: 1 });
        await search(1);
    };

    const handleClear = async () => {
        dispatch({ type: "SET_CURRENT_PAGE", payload: 1 });
        dispatch({ type: "SET_FORM_DATA", payload: {} });
        if (prevFrame !== null && isUserStory === true) {
            dispatch({
                type: "SET_FORM_DATA",
                payload: prevFrameData,
            });
        }
        await search(1, true);
    };

    return template.tableName !== "" ? (
        <div>
            <SearchToggleContainer>
                <PrimaryButton
                    onClick={() => {
                        setShowSearchForm((prev) => !prev);
                    }}
                    $text={`${
                        showSearchForm === false ? "Show" : "Hide"
                    } ${"Search Form"}`}
                    style={{ borderRadius: "5px", width: "200px" }}
                />
            </SearchToggleContainer>
            <div
                style={{
                    display: `${showSearchForm === false ? "none" : "block"}`,
                }}
            >
                {template &&
                    template.searchComponent &&
                    template.searchComponent.fields &&
                    template.searchComponent.fields.length > 0 && (
                        <Card
                            body={
                                <SearchForm
                                    formData={formData}
                                    fields={template.searchComponent.fields}
                                    windowWidth={windowWidth}
                                    dispatch={dispatch}
                                />
                            }
                            footer={[
                                <PrimaryButton
                                    onClick={() => {
                                        handleSubmit();
                                        setIsFetching(true);
                                    }}
                                    $text="Search"
                                    disabled={false}
                                />,
                                <PrimaryButton
                                    $text="Clear Filter"
                                    onClick={() => {
                                        handleClear();
                                        setIsFetching(true);
                                    }}
                                    disabled={false}
                                />,
                            ]}
                        />
                    )}
            </div>
            <div>
                {needCreate === true && (
                    <CreateSearchContainer>
                        <PrimaryButton
                            onClick={() => {
                                setShowModal((prev) => !prev);
                            }}
                            $text="Create"
                            style={{ borderRadius: "10px" }}
                        />
                        {showModal && (
                            <Modal
                                $isOpen={showModal}
                                setOpenModal={setShowModal}
                            >
                                <Modal.Header>
                                    Create Form - {templateName}
                                </Modal.Header>
                                <Modal.Body>
                                    {isBulkCreate === false ? (
                                        <OpCodeCreateForm
                                            templateName={templateName}
                                        />
                                    ) : (
                                        <OpCodeBulkCreateForm
                                            templateName={templateName}
                                        />
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <PrimaryButton
                                            $text="Close"
                                            onClick={() => {
                                                setShowModal(false);
                                            }}
                                            style={{ backgroundColor: "black" }}
                                        />
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </CreateSearchContainer>
                )}
            </div>
            <div>
                {tableData && (
                    <Card
                        body={
                            <Table
                                isFetching={isFetching}
                                templateName={templateName}
                                OpCodeEditTemplateName={
                                    template.OpCodeEditTemplateName
                                }
                                tableName={template.tableName}
                                tableCol={template.tableComponent.tableCol}
                                tableData={tableData}
                                totalFetchedRow={totalFetchedRow}
                                haveSelect={isUserStory ? true : false}
                                dispatch={dispatch}
                                tableDisplayOrder={
                                    template.tableComponent.resultDisplayOrder
                                }
                            />
                        }
                        footer={[
                            tableData && totalFetchedRow > 0 && (
                                <PaginationContainer>
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPage}
                                        totalRows={totalRows}
                                        maxNumberOfRows={
                                            template.tableComponent
                                                .maxRowsPerPage
                                        }
                                        totalFetchedRow={totalFetchedRow}
                                        onPageChange={(page) => {
                                            dispatch({
                                                type: "SET_CURRENT_PAGE",
                                                payload: page,
                                            });
                                            search(page);
                                        }}
                                        setFetching={setIsFetching}
                                    />
                                </PaginationContainer>
                            ),
                        ]}
                        footerCenter={false}
                    />
                )}
            </div>
            {/* {
            showModal &&
            <Modal open={showModal} title='OpCode Create' onClose={() => setShowModal((prev) => !prev)}>
              {
                template.formComponent?.isBulk === true? 
                  <OpCodeBulkCreate templateName={templateName} className="app" />
                :
                  <OpCodeCreate templateName={templateName} className="app" />
              }
            </Modal>
          } */}
        </div>
    ) : (
        <div>
            <AppLoader />
        </div>
    );
}
