import React from "react";
import OpCodeBulkCreateForm from "../../components/OpCodeBulkCreateForm";
import "./op-code-bulk-create-styles.css";

export default function OpCodeBulkCreate() {
    const urlParams = new URLSearchParams(window.location.search);
    const templateName = urlParams.get("templateName");

    return (
        <div>
            <h1 className="op-code-bulk-create-heading">OpCode Bulk Create</h1>
            <OpCodeBulkCreateForm templateName={templateName} />
        </div>
    );
}
