import React from "react";
import styled from "styled-components";

const Button = styled.button`
    display: flex;
    width: 120px;
    padding: 8px 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const PrimaryButtonDiv = styled(Button)`
    background-color: ${(props) => (props.disabled ? "#B0BEC5" : "#B1092A")};
    color: white;
    border-radius: 20px;
    border: none;

    &:hover {
        background-color: ${(props) =>
            props.disabled ? "#B0BEC5" : "#0C0C0C"};
    }
`;

const DivBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

const Span = styled.span`
    font-size: 18px;
    font-weight: 500;
`;

export const PrimaryButton = (props) => {
    return (
        <PrimaryButtonDiv {...props} disabled={props.disabled}>
            <DivBtn>
                <span>{props.$icon}</span>
                <Span>{props.$text}</Span>
            </DivBtn>
        </PrimaryButtonDiv>
    );
};
