import { styled } from "styled-components";
import { InputField, RadioField } from "./InputField";
import { DropDownField } from "./DropDown";

const FormFieldContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const FormField = styled.div`
    padding: 20px 15px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    min-height: 115px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const FormFieldTitle = styled.label`
    color: #b1092a;
    font-weight: 500;
    display: block;
    padding-bottom: 10px;
`;

const FormFieldInputError = styled.span`
    color: #b1092a;
    display: block;
    font-size: 12px;
    padding-top: 5px;
    font-style: italic;
`;

export function SearchForm({ formData, fields, windowWidth, dispatch }) {
    return (
        <FormFieldContainer>
            {fields.map((item, index) => (
                <div
                    key={index}
                    className="form-field-container"
                    style={{
                        gridColumn: `${
                            windowWidth > 700
                                ? item.visible !== false
                                    ? `span ${item?.size}`
                                    : null
                                : "span 1"
                        }`,
                        display: `${item.visible !== false ? "block" : "none"}`,
                    }}
                >
                    {item.visible !== false &&
                        ["text", "number", "date", "email"].includes(
                            item.type || item.inputType,
                        ) && (
                            <FormField>
                                <FormFieldTitle htmlFor={item.key}>
                                    {item.label}
                                </FormFieldTitle>
                                <InputField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                            </FormField>
                        )}
                    {item.visible !== false &&
                        (item.type === "dropdown" ||
                            item.inputType === "dropdown") && (
                            <FormField>
                                <FormFieldTitle htmlFor={item.key}>
                                    {item.label}
                                </FormFieldTitle>
                                <DropDownField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                            </FormField>
                        )}
                    {item.visible !== false &&
                        (item.type === "radio" ||
                            item.inputType === "radio") && (
                            <FormField>
                                <FormFieldTitle htmlFor={item.key}>
                                    {item.label}
                                </FormFieldTitle>
                                <RadioField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                            </FormField>
                        )}
                </div>
            ))}
        </FormFieldContainer>
    );
}

export function CreateForm({
    formData,
    fields,
    windowWidth,
    fieldErrors,
    dispatch,
}) {
    return (
        <FormFieldContainer>
            {fields.map((item, index) => (
                <div
                    key={index}
                    className="form-field-container"
                    style={{
                        gridColumn: `${
                            windowWidth > 700
                                ? item.visible !== false
                                    ? `span ${item?.size}`
                                    : null
                                : "span 1"
                        }`,
                        display: `${item.visible !== false ? "block" : "none"}`,
                    }}
                >
                    {item.visible !== false &&
                        ["text", "number", "date", "email"].includes(
                            item.type || item.inputType,
                        ) && (
                            <FormField>
                                <FormFieldTitle
                                    htmlFor={item.key || item.field}
                                >
                                    {item.label} {item.required && "*"}
                                </FormFieldTitle>
                                <InputField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                    item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}
                    {item.visible !== false &&
                        (item.type || item.inputType) === "dropdown" && (
                            <FormField>
                                <FormFieldTitle
                                    htmlFor={item.key || item.field}
                                >
                                    {item.label} {item.required && "*"}
                                </FormFieldTitle>
                                <DropDownField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                    item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}
                    {item.visible !== false &&
                        (item.type || item.inputType) === "radio" && (
                            <FormField>
                                <FormFieldTitle
                                    htmlFor={item.key || item.field}
                                >
                                    {item.label} {item.required && "*"}
                                </FormFieldTitle>
                                <RadioField
                                    item={item}
                                    formData={formData}
                                    dispatch={dispatch}
                                />
                                {fieldErrors &&
                                    fieldErrors[item.key || item.field] &&
                                    !formData[item.key || item.field] && (
                                        <FormFieldInputError>
                                            {
                                                fieldErrors[
                                                    item.key || item.field
                                                ]
                                            }
                                        </FormFieldInputError>
                                    )}
                            </FormField>
                        )}
                </div>
            ))}
        </FormFieldContainer>
    );
}
