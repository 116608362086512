import { styled } from "styled-components";
import { CgClose } from "react-icons/cg";
import { LoaderSpinner } from "./LoaderSpinner";

const ModalBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
`;

const ModalContainer = styled.div`
    width: 450px;
    height: 200px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding: 20px;
`;

const CloseBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const CloseBtn = styled.button`
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
`;

const TitleContainer = styled.div`
    display: inline-block;
    text-align: center;
    margin-top: 10px;
`;

const MassageContainer = styled.div`
    height : 25px;
    width: 100%
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
`;

const BtnContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
`;

const CancelBtn = styled.button`
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 25px;
    font-size: 15px;
    border: 1px solid rgb(185, 185, 185);
    cursor: pointer;
    padding: 10px 15px;
    &:hover {
        background-color: #e5e5e5;
    }
`;

const PermanentDeleteBtn = styled.button`
    min-width: 170px;
    border: none;
    color: white;
    background-color: red;
    &:hover {
        // background-color: var(--primary-color-hover);
    }
    border-radius: 25px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 15px;
`;

export default function AlertModal({
    openModal,
    setOpenModal,
    onDelete,
    loading = false,
    title = "Are you sure you want to delete this item",
    message = "Once deleted, this item will no longer be accessible.",
    buttonText = "Permanently delete",
}) {
    const handleCloseModal = () => {
        if (loading) return;
        setOpenModal(false);
    };

    return (
        <>
            {openModal && (
                <ModalBackground onClick={handleCloseModal}>
                    <ModalContainer
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <CloseBtnContainer>
                            <CloseBtn onClick={handleCloseModal}>
                                <CgClose size={20} />
                            </CloseBtn>
                        </CloseBtnContainer>
                        <TitleContainer>
                            <h3>{title}</h3>
                        </TitleContainer>
                        <MassageContainer>
                            <span>{message}</span>
                        </MassageContainer>
                        <BtnContainer>
                            <CancelBtn onClick={handleCloseModal}>
                                Cancel
                            </CancelBtn>
                            <PermanentDeleteBtn onClick={onDelete}>
                                {loading ? <LoaderSpinner /> : buttonText}
                            </PermanentDeleteBtn>
                        </BtnContainer>
                    </ModalContainer>
                </ModalBackground>
            )}
        </>
    );
}
