/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { httpRequest2 } from "../utils/httpsRequest";
import { UserStoryContext } from "../context/UserStoryState";
import { PrimaryButton } from "./Buttons";

const ViewDetailContainer = styled.div``;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export default function OpCodeViewDetail({
    templateName,
    data,
    changeFrame,
    buttonActive,
    contextField,
}) {
    const [viewDetails, setViewDetails] = useState({});
    const {
        ResetViewDetailData,
        AddContextData,
        viewDetailTableName,
        viewDetailRecordID,
    } = useContext(UserStoryContext);

    const [template, setTemplate] = useState({
        templateName: "",
        tableName: "",
        viewDetailComponent: {},
    });

    useEffect(() => {
        const getTemplate = async () => {
            // await httpRequest.get(`/bexo/insights/bex3d-templates/opcode/search/${templateName}`)
            await httpRequest2
                .get(`/api/template/${templateName}`)
                .then(async (response) => {
                    if (response.data.length !== 0) {
                        await setTemplate(response.data);

                        if (
                            viewDetailRecordID !== "" &&
                            viewDetailRecordID !== "" &&
                            template?.viewDetailComponent?.fields !== undefined
                        ) {
                            httpRequest2
                                .post(`/api/viewDetails`, {
                                    target: viewDetailTableName,
                                    user_id: viewDetailRecordID,
                                    fields: response.data.viewDetailComponent
                                        .fields,
                                })
                                .then((res) => {
                                    setViewDetails(res.data);
                                })
                                .catch((err) => console.log(err));
                        } else {
                            setViewDetails({});
                        }
                    }
                })
                .catch((err) => console.log(err));
        };

        getTemplate();
    }, [templateName, viewDetailRecordID]);

    const sendContextData = (templateName, contextField) => {
        const contextFields = contextField;
        let temp = {};
        for (const item of contextFields) {
            temp[item] = viewDetails[item] || "";
        }
        AddContextData({ templateName: templateName, values: temp });
    };

    return (
        <div>
            {Object.keys(viewDetails).length !== 0 && (
                <ViewDetailContainer>
                    {template &&
                        template.viewDetailComponent &&
                        template.viewDetailComponent.fields.map(
                            (item, index) => (
                                <div key={index}>
                                    <h3>
                                        {item?.title} {item?.heading}
                                    </h3>
                                    <p style={{ whiteSpace: "wrap" }}>
                                        {viewDetails[item?.key]}
                                    </p>
                                </div>
                            ),
                        )}
                    <ButtonContainer>
                        <PrimaryButton
                            $text="Next"
                            onClick={() => {
                                changeFrame();
                                sendContextData(
                                    data.templateName,
                                    contextField,
                                );
                                ResetViewDetailData();
                            }}
                            disabled={!buttonActive}
                        />
                    </ButtonContainer>
                </ViewDetailContainer>
            )}
        </div>
    );
}
